export interface IncidentStatusDTO {
  id: number;
  title_en: string;
  title_ar: string;
  description_en: string;
  description_ar: string;
  color_code: string;
  type: string;
  status: string;
}

export interface IncidentStatusDetailsDTO {
  id: number;
  title: string;
  description: string;
  color_code: string;
  type: string;
  status: string;
}

export function convertResponceToIncidentStatusDetailsDTO(
  responce: any,
  lang: string
): IncidentStatusDetailsDTO {
  return {
    id: responce.id,
    title: responce[`title_${lang}`],
    description: responce[`description_${lang}`],
    color_code: responce.color_code,
    type: responce.type,
    status: responce.status,
  };
}
