export interface IncidentCategoryDTO {
  id: number;
  title_en: string;
  title_ar: string;
  status: string;
}

export interface IncidentCategoryFormDTO {
  id?: number;
  title_en: string;
  title_ar: string;
  status: string;
}

export interface IncidentCategoryDetailsDTO {
  id: number;
  title: string;
  status: string;
}

export function convertResponceToIncidentCategoryDetailsDTO(
  responce: any,
  lang: string
): IncidentCategoryDetailsDTO | null {
  if (!responce) {
    return null;
  }
  return {
    id: responce.id,
    title: responce[`title_${lang}`],
    status: responce.status,
  };
}
