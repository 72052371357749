export interface IncidentTypeDTO {
  id: number;
  title_en: string;
  title_ar: string;
  status: string;
}

export interface IncidentTypeFormDTO {
  id?: number;
  title_en: string;
  title_ar: string;
  status: string;
}

export interface IncidentTypeDetailsDTO {
  id: number;
  title: string;
  status: string;
}

export function convertResponceToIncidentTypeDetailsDTO(
  responce: any,
  lang: string
): IncidentTypeDetailsDTO | null {
  if (!responce) {
    return null;
  }
  return {
    id: responce.id,
    title: responce[`title_${lang}`],
    status: responce.status,
  };
}
