export interface IncidentSubCategoryDTO {
  id: number;
  title_en: string;
  title_ar: string;
  incident_category_title: string;
  incident_category_id: number;
  status: string;
}

export interface IncidentSubCategoryFormDTO {
  id?: number;
  title_en: string;
  title_ar: string;
  incident_category_title?: string;
  incident_category_id: number;
  status: string;
}

export interface IncidentSubCategoryDetailsDTO {
  id: number;
  incident_category_id: number;
  title: string;
  incident_category_title: string;
  status: string;
}

export function convertResponceToIncidentSubCategoryDetailsDTO(
  responce: any,
  lang: string
): IncidentSubCategoryDetailsDTO | null {
  if (!responce) {
    return null;
  }
  return {
    id: responce.id,
    incident_category_id: responce.incident_category_id,
    title: responce[`title_${lang}`],
    incident_category_title: responce.incident_category_title,
    status: responce.status,
  };
}
